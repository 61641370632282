.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.justify-left {
  text-align: left;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.font-face-bb {
  font-family: 'Birthstone Bounce'
}

.font-face-ms {
  font-family: 'Montserrat'
}

.font-face-ms-bold {
  font-family: 'Montserrat';
  font-weight: 700;
}

.font-face-ps {
  font-family: 'Pinyon Script'
}

.font-face-ps-bold {
  font-family: 'Pinyon Script';
  font-weight: 700;
}

main h2 {
  letter-spacing: .15em;
  font-weight: 600;
}

main h2::after {
  content: '___';
  letter-spacing: -.15em;
  display: block;
  margin-top: -15px;
}

.h3_cust {
  letter-spacing: .15em;
  font-weight: 600;
}

.h3_cust::after {
  content: '___';
  letter-spacing: -.15em;
  display: block;
  margin-top: -15px;
}


.carousel-caption h3 {
  font-size: xxx-large;
  text-shadow: 1px 1px 2px black;
}

.carousel-caption p {
  font-size: x-large;
  text-shadow: 1px 1px 2px black;
}

.carousel-caption-cust h1 {
  text-shadow: 2px 2px 3px black;
  letter-spacing: .25em;
  height: 50%; 
  width:100%;
  display:flex;
  align-items: center;
  justify-content: center;
  font-size: xxx-large;
}

.footer-text h1{
  letter-spacing: .25em;
  color: black;
  text-align: center;
  position: absolute;
  top: -80%;
  bottom: 0;
  left: 15%;
  right: 15%;
  height: fit-content;
  margin: auto;
  font-size: xx-large
}

.footer-text h3{
  letter-spacing: .25em;
  color: black;
  text-align: center;
  position: absolute;
  top: -95%;
  bottom: 0;
  left: 15%;
  right: 15%;
  height: fit-content;
  margin: auto;
  font-size: x-large
}

@media screen and (max-width: 600px) {
  .footer-text h1{
    letter-spacing: .25em;
    color: black;
    text-align: center;
    position: absolute;
    top: -65%;
    bottom: 0;
    left: 15%;
    right: 15%;
    height: fit-content;
    margin: auto;
    font-size: 15px;
  }

  .footer-text h3{
    letter-spacing: .25em;
    color: black;
    text-align: center;
    position: absolute;
    top: -85%;
    bottom: 0;
    left: 15%;
    right: 15%;
    height: fit-content;
    margin: auto;
    font-size: 15px;
  }
}

.banner-text {
  text-shadow: 1px 1px 1px black;
  letter-spacing: .25em;
  color: white;
  text-align: right;
  position: absolute;
  top: -15%;
  bottom: 0;
  left: 0;
  right: 15%;
  height: fit-content;
  margin: auto;
  font-size: xxx-large
}

.banner-subtitle {
  text-shadow: 1px 1px 1px black;
  letter-spacing: .25em;
  color: white;
  text-align: right;
  position: absolute;
  top: 10%;
  bottom: 0;
  left: 0;
  right: 22%;
  height: fit-content;
  margin: auto;
  font-size: x-large;
}

.banner-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

@media screen and (max-width: 600px) {
  .banner-text {
    text-shadow: 1px 1px 1px black;
    letter-spacing: .25em;
    color: white;
    text-align: right;
    position: absolute;
    top: -15%;
    bottom: 0;
    left: 0;
    right: 5%;
    height: fit-content;
    margin: auto;
    font-size: 15px;
  }
}

@media screen and (max-width: 600px) {
  .banner-subtitle {
    text-shadow: 1px 1px 1px black;
    letter-spacing: .25em;
    color: white;
    text-align: right;
    position: absolute;
    top: 10%;
    bottom: 0;
    left: 0;
    right: 10%;
    height: fit-content;
    margin: auto;
    font-size: 12px;
  }
}

@media screen and (max-width: 600px) {
  .carousel-caption-cust h1 {
    text-shadow: 1px 1px 1px black;
    letter-spacing: .25em;
    height: 100%; 
    width:100%;
    display:block;
    align-items: center;
    justify-content: center;
    font-size: medium;
  }
  
  .carousel-caption h3 {
    font-size: x-large;
    text-shadow: 1px 1px 2px black;
  }
  
  .carousel-caption p {
    font-size: large;
    text-shadow: 1px 1px 2px black;
  }

}

.carousel-control-prev-icon, .carousel-control-next-icon {
  width: 4rem;
  height: 4rem;
}

.carousel-caption-cust h2 {
  text-shadow: 1px 1px 1px black;
  letter-spacing: .25em;
  height: 50%; 
  width:100%;
  display:flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 600px) {
  .carousel-caption-cust h2 {
    text-shadow: 1px 1px 1px black;
    letter-spacing: .25em;
    height: 100%; 
    width:100%;
    display:block;
    align-items: center;
    justify-content: center;
    font-size: small;
  }
}

.carousel-caption p {
  text-shadow: 1px 1px 2px black;
}

.carousel-caption-cust {
  top: 50%;
  transform: translateY(-50%);
  bottom: initial;
  text-align: center;
}

@media screen and (max-width: 600px) {
  .carousel-caption-cust {
    top: 25%;
    transform: translateY(-50%);
    bottom: initial;
    text-align: center;
  }
}

.navbar-brand {
  font-family: 'Montserrat'
}


.carousel-cust {
  display: flex;
  overflow: hidden;
  height: 100%;
}

.carousel-item-cust {
  flex: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  
}

.table tbody {
  text-align: left;
}


.infotable tbody {
  text-align: left;
}

.infotable tbody td {
  background-color: rgba(0, 0, 0, 0);
}




